







































































































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  Listing,
} from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';

@Component
export default class TVDisplaySales extends Mixins(View) {
  private dwellTime = 22 * 1000 * 1;

  private maxListings = 100;

  private isFullScreen = false;

  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: this.maxListings,
  });

  private listings: Listing[] = [];

  private listing: Listing | null = null;

  private nextTimeout?: number;

  private currentIdx = 0;

  get listingLink() {
    if (this.listing === null) return '';

    return `${window.location.hostname}/${this.listing.PropertyId}`;
  }

  get qrCodeLink() {
    if (this.listing === null) return null;

    return `${Config.API.Base}/api/links/qrcode/listings/${this.listing.Id}?clientWebsiteId=${Config.Website.Id}`;
  }

  get methodOfSale() {
    if (this.listing === null) return 'For Sale';

    if (this.listing.MethodOfSale.indexOf('For') !== -1) return this.listing.MethodOfSale;

    return `For ${this.listing.MethodOfSale}`;
  }

  fullScreen() {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  queueNext() {
    clearTimeout(this.nextTimeout);

    this.nextTimeout = setTimeout(() => {
      this.currentIdx += 1;

      if (this.currentIdx >= this.listings.length && this.listings.length > 0) {
        this.updateListings().then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.currentIdx = 0;
          this.listing = null;
        });
      } else {
        this.listing = null;
      }
    }, this.dwellTime);
  }

  handleAfterLeave() {
    this.listing = this.listings[this.currentIdx];

    console.log(this.listing);

    this.queueNext();
  }

  updateListings() {
    return new Promise((resolve) => {
      API.Listings.Search(this.listingFilter).then((listings) => {
        this.listings = listings.Items;

        this.handleAfterLeave();

        resolve();
      }).catch(() => {
        if (this.listings.length > 0) {
          this.handleAfterLeave();

          resolve();
        }
      });
    });
  }

  mounted() {
    this.updateListings();
  }
}
